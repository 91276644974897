

















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState(['isDarkMode', 'isHiddenPshs']),
})
export default class NavDrawer extends Vue {
  public isDarkMode!: boolean
  public isHiddenPshs!: boolean
  public visible: boolean = false
  private darkWaves = require('../assets/wavy-dark.svg')
  private lightWaves = require('../assets/wavy.svg')

  public created() {
    this.$bus.$on('open-drawer', () => this.visible = true)
  }

  public setChanged(set: string) {
    this.$store.dispatch('updateCurrentSet', set)
    this.visible = false
  }

  public openLink(link: string) {
    window.open(link, '_blank', 'noopener')
    this.visible = false
  }

  public showDonate() {
    this.$bus.$emit('show-donate-dialog')
    this.visible = false
  }

  get appVersion(): string {
    return this.$store.getters.appVersion
  }
  get currentSet(): string {
    return this.$store.getters.currentSet
  }
  get subjects(): string[] {
    let subjects: string[] = []
    const customSets = this.$store.getters.customSets

    if (customSets.length > 0) {
      subjects = subjects.concat(customSets)
    }

    if (!this.isHiddenPshs) {
      subjects = subjects.concat(this.$store.getters.pshsSets)
    }

    return subjects
  }
}
